<template>
  <div class="detail">
    <Navlid :list="navlist"></Navlid>
    <div class="shopInfo">
      <img class="logo" v-preview v-img :src="detail.merchants_logo" />
      <p>{{ detail.contact_name }}</p>
      <div class="status">
        <div v-if="!is_temporary">
          合同状态：
          <span :class="detail?.merchants_contract_list?.length ? 'done' : 'fail'">{{
            detail?.merchants_contract_list?.length ? "已签订" : "未签订"
          }}</span>
        </div>
        <div>
          入驻时间：
          <span>{{ detail.create_at }}</span>
        </div>
      </div>
    </div>

    <el-tabs type="border-card">
      <el-tab-pane label="基本信息">
        <div class="table">
          <table border style="width: 100%">
            <tr>
              <td>
                <div>商户名称：</div>
              </td>
              <td>{{ detail.contact_name }}</td>
              <td>
                <div>商户姓名：</div>
              </td>
              <td>{{ detail.merchants_name }}</td>
            </tr>
            <tr>
              <td>
                <div>证件号码：</div>
              </td>
              <td>{{ detail.merchants_card_id }}</td>
              <td>
                <div>联系电话：</div>
              </td>
              <td>{{ detail.merchants_contact }}</td>
            </tr>
            <tr>
              <td>
                <div>商户号：</div>
              </td>
              <td>{{ detail.merchants_number }}</td>
              <td>
                <div>商铺名称：</div>
              </td>
              <td>{{ detail.merchants_stall_name }}</td>
            </tr>
            <tr>
              <td>
                <div>营业执照：</div>
              </td>
              <td>
                <el-button type="text" v-preview="detail.merchants_business_license">查看</el-button>
              </td>
              <td>
                <div>经营许可证：</div>
              </td>
              <td>
                <el-button type="text" v-preview="detail.merchants_certificate">查看</el-button>
              </td>
            </tr>
            <tr>
              <td>
                <div>商户类型：</div>
              </td>
              <td>
                <span>{{ detail.merchants_type == 1 ? "个体" : "企业" }}</span>
              </td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="账单信息">
        <div class="public-table">
          <el-table :data="billList" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column label="流水号" prop="bill_no"></el-table-column>
            <el-table-column label="收费项名称" prop="contract_fee_name"></el-table-column>
            <el-table-column v-if="is_temporary" label="租赁开始时间" prop="property_time"></el-table-column>
            <el-table-column v-if="is_temporary" label="租赁结束时间" prop="property_end_time"></el-table-column>
            <el-table-column label="缴费时间" prop="pay_time">
              <template #default="{ row }">
                <span>{{ $common.formatTimeHms(row.pay_time) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200" label="缴费周期" prop="property_time">
              <template #default="{ row }">
                <div>{{ row.property_time }} ~ {{ row.property_end_time }}</div>
              </template>
            </el-table-column>
            <el-table-column label="优惠金额" prop="give_discount"></el-table-column>
            <el-table-column label="减免金额" prop="coupon_price"></el-table-column>
            <el-table-column label="余额抵扣金额" prop="balance_price"></el-table-column>
            <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
            <el-table-column label="实缴金额" prop="key_update_price"></el-table-column>
            <el-table-column label="剩余应缴金额" prop="key_pay_price"></el-table-column>
            <el-table-column label="总金额" prop="key_total_price"></el-table-column>
            <el-table-column label="缴费方式">
              <template #default="{ row }">
                <span>{{
                  $chargeWay.find((item) => item.id == row.pay_type)
                  ? $chargeWay.find((item) => item.id == row.pay_type).value
                  : "/"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="收费人" prop="bill_payee"></el-table-column>
          </el-table>
          <div class="apiPage">
            <el-pagination :total="page.total" :current-page="page.pageNum" :page-size="page.pageSize"
              layout="total,prev,pager,next" next-text="下一页" prev-text="上一页" @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="合同信息" v-if="!is_temporary">
        <div class="yc-table">
          <el-table :data="detail.merchants_contract_list" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }" :cell-style="{ 'text-align': 'center' }">
            <el-table-column prop="contract_no" label="合同编号"></el-table-column>
            <!-- <el-table-column label="商铺租赁"></el-table-column> -->
            <el-table-column label="总基础费用" prop="total_price"></el-table-column>
            <el-table-column prop="contract_start" label="合同开始时间"></el-table-column>
            <el-table-column prop="contract_end" label="合同到期时间"></el-table-column>
            <el-table-column label="合同天数" prop="contract_reduce"></el-table-column>
            <el-table-column label="操作">
              <template #default="{ row }">
                <pop popLeft tips="详情" @click="
                  $router.push(
                    `/contract/contractdetail?id=${row.contract_id}`
                  )
                  ">
                  <img class="icon" src="@/assets/img/icon/xiangqing.png" alt="" />
                </pop>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="!is_temporary" label="其他信息">
        <div class="table">
          <table border style="width: 100%">
            <tr>
              <td>
                <div>已缴总费用(元)：</div>
              </td>
              <td>{{ detail.merchants_pay_total }}</td>
              <td>
                <div>入驻总时间(天)：</div>
              </td>
              <td>{{ checkInTime }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "detaile",
  data () {
    return {
      navlist: [
        {
          title: "商户列表",
          url: "/merchants",
        },
        {
          title: "商户详情",
        },
      ],
      tableList: [],
      detail: {},
      checkInTime: "",
      is_temporary: false, //是否临时商铺
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      billList: [],
    };
  },
  mounted () {
    this.getDetail();
    this.getBillList();
  },

  methods: {
    /**
     *
     * @param {*} starDay 开始时间 2022/10/01 00:00:00
     */
    cacal (starDay) {
      if (!starDay) {
        this.checkInTime = 0;
        return;
      }
      const date2 = new Date(); //结束时间
      const date3 = date2.getTime() - new Date(starDay).getTime(); //时间差的毫秒
      const days = Math.floor(date3 / (24 * 3600 * 1000));

      this.checkInTime = days;
    },
    getDetail () {
      this.$request
        .HttpGet("/merchants/edit", { id: this.$route.query.id })
        .then((res) => {
          if (res.data) {
            this.detail = res.data;
            this.is_temporary = this.detail.is_temporary == 1;
            this.cacal(this.detail.create_at);
          } else {
            this.$message({ message: "暂未查到商户信息" });
            this.$router.back();
          }
        });
    },
    //账单信息
    getBillList () {
      this.$request.HttpGet("/bill/list", {
        merchants_id: this.$route.query.id,
        bill_status: 1,
        ...this.page,
      }).then((res) => {
        this.billList = res.data.list;
        this.page.total = res.data.total;
      });
    },
    currentChange (page) {
      this.page.pageNum = page;
      this.getBillList();
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  .shopInfo {
    flex: 1;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #dedede;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 10px;

    .logo {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 120px;
      height: 90px;
    }

    p {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }

    .status {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      div {
        margin-right: 50px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;

        &:last-child {
          span {
            color: #000000;
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;

      div {
        height: 32px;
        background: #f9f9f9;
        line-height: 32px;
        opacity: 1;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  table {
    border: 1px solid #dedede;

    tr {
      height: 37px;

      td {
        padding-left: 20px;

        &:nth-child(2n + 1) {
          background: #f5f5f5;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            width: 120px;
            height: 37px;
            line-height: 37px;
            text-align: right;
            background: #f5f5f5;
          }
        }
      }
    }
  }
}

.fail {
  color: #e90000;
}

.done {
  color: #02ce80;
}

.yc-table {
  table {
    tr {
      td {
        padding-left: 20px;

        &:nth-child(2n + 1) {
          div {
            width: unset;
            height: unset;
            line-height: unset;
            text-align: center;
            background: unset;
          }
        }
      }
    }
  }
}
</style>
