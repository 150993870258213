<template>
  <KeepAlive :include="['batchcontract']">
    <router-view :key="$route.fullPath"></router-view>
  </KeepAlive>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss"></style>
