<template>
  <div class="detail">
    <Navlid :list="navlist"></Navlid>
    <div class="shopInfo">
      <img class="logo" v-preview v-img :src="detail.merchants_logo" />
      <p>{{ detail.contact_name }}</p>
      <div class="status">
        <div>
          合同状态：
          <span
            :class="detail?.merchants_contract_list?.length ? 'done' : 'fail'"
            >{{
              detail?.merchants_contract_list?.length ? "已签订" : "未签订"
            }}</span
          >
        </div>
        <div>
          入住时间：
          <span>{{ detail.create_at }}</span>
        </div>
      </div>
      <!-- <div class="btns">
        <MyButton>修改
          <template slot="preImage">
            <img src="../../unit/img/search.png" alt="">
          </template>
        </MyButton>
        <MyButton>删除
          <template slot="preImage">
            <img src="../../unit/img/search.png" alt="">
          </template>
        </MyButton>
      </div> -->
    </div>
    <div class="title">基本信息</div>
    <div class="table">
      <table border style="width: 100%">
        <tr>
          <td>
            <div>商户名称：</div>
          </td>
          <td>{{ detail.contact_name }}</td>
          <td>
            <div>商户姓名：</div>
          </td>
          <td>{{ detail.merchants_name }}</td>
        </tr>
        <tr>
          <td>
            <div>证件号码：</div>
          </td>
          <td>{{ detail.merchants_card_id }}</td>
          <td>
            <div>联系电话：</div>
          </td>
          <td>{{ detail.merchants_contact }}</td>
        </tr>
        <tr>
          <td>
            <div>商户号：</div>
          </td>
          <td>{{ detail.merchants_number }}</td>
          <td>
            <div>商铺名称：</div>
          </td>
          <td>{{ detail.merchants_stall_name }}</td>
        </tr>
        <tr>
          <td>
            <div>营业执照：</div>
          </td>
          <td>
            <el-button type="text" v-preview="detail.merchants_business_license"
              >查看</el-button
            >
          </td>
          <td>
            <div>经营许可证：</div>
          </td>
          <td>
            <el-button type="text" v-preview="detail.merchants_certificate"
              >查看</el-button
            >
          </td>
        </tr>
        <tr>
          <td>
            <div>商户类型：</div>
          </td>
          <td>
            <span>{{ detail.merchants_type == 1 ? "个体" : "企业" }}</span>
          </td>
        </tr>
      </table>
    </div>
    <div class="title">租赁记录</div>
    <div class="public-table">
      <el-table
        :data="detail.merchants_bill_list"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="流水号" prop="bill_no"></el-table-column>
        <el-table-column
          label="收费项名称"
          prop="contract_fee_name"
        ></el-table-column>
        <el-table-column label="缴费时间" prop="pay_time"></el-table-column>
        <el-table-column
          label="优惠金额"
          prop="give_discount"
        ></el-table-column>
        <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
        <el-table-column
          label="实缴金额"
          prop="key_update_price"
        ></el-table-column>
        <el-table-column
          label="剩余应缴金额"
          prop="key_pay_price"
        ></el-table-column>
        <el-table-column
          label="总金额"
          prop="key_total_price"
        ></el-table-column>

        <el-table-column label="缴费方式">
          <template #default="{ row }">
            <span>{{
              $chargeWay.find((item) => item.id == row.pay_type)
                ? $chargeWay.find((item) => item.id == row.pay_type).value
                : "/"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收费人" prop="bill_payee"></el-table-column>

        <!-- <el-table-column label="状态"></el-table-column> -->
        <!-- <el-table-column label="操作">
          <template>
            <div class="slot-box">
              <pop tips="编辑">
                <img src="../../unit/img/edit.png" class="icon" alt="">
              </pop>
              <pop tips="删除">
                <img src="../../unit/img/edit.png" class="icon" alt="">
              </pop>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="title">收费记录</div>
    <div class="public-table">
      <el-table
        :data="detail.merchants_contract_list"
        :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="contract_no" label="合同编号"></el-table-column>
        <el-table-column label="商铺租赁"></el-table-column>
        <el-table-column label="总基础费用"></el-table-column>
        <el-table-column
          prop="contract_start"
          label="合同开始时间"
        ></el-table-column>
        <el-table-column
          prop="contract_end"
          label="合同到期时间"
        ></el-table-column>
        <el-table-column label="合同天数"></el-table-column>
        <el-table-column label="状态"></el-table-column>
        <el-table-column label="操作"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "detaile",
  data() {
    return {
      navlist: [
        {
          title: "商户列表",
          url: "/merchants",
        },
        {
          title: "商户详情",
        },
      ],
      tableList: [],
      detail: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$request
        .HttpGet("/merchants/edit", { id: this.$route.query.id })
        .then((res) => {
          if (res.data) {
            this.detail = res.data;
          } else {
            this.$message({ message: "暂未查到商户信息" });
            this.$router.back();
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  .shopInfo {
    flex: 1;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #dedede;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    .logo {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 120px;
      height: 90px;
    }

    p {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }

    .status {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      div {
        margin-right: 50px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;

        &:last-child {
          span {
            color: #000000;
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;

      div {
        height: 32px;
        background: #f9f9f9;
        line-height: 32px;
        opacity: 1;
        margin-right: 20px;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  table {
    border: 1px solid #dedede;

    tr {
      height: 37px;

      td {
        padding-left: 20px;
        &:nth-child(2n + 1) {
          background: #f5f5f5;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            width: 120px;
            height: 37px;
            line-height: 37px;
            text-align: right;
            background: #f5f5f5;
          }
        }
      }
    }
  }
}
.fail {
  color: #e90000;
}
.done {
  color: #02ce80;
}
</style>
